import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';

import { useAppState } from 'src/AppStateProvider';
import { FlexColumn } from 'src/components/flex';
import { EHelpSidebarView } from 'src/components/layout/landlord/help-sidebar/types';
import HelpSidebarMainContent from 'src/components/layout/landlord/help-sidebar/HelpSidebarMainContent';
import HelpSidebarKeyboardShortcuts from 'src/components/layout/landlord/help-sidebar/HelpSidebarKeyboardShortcuts';

export default function HelpSidebar() {
    const {
        isHelpSidebarOpened,
        setHelpSidebarOpened,
        setTawkChatOpened,
    } = useAppState();

    const [view, setView] = useState<EHelpSidebarView>(EHelpSidebarView.Main);

    const handleClose = () => {
        // reset the view to the main view when the sidebar is closed
        setView(EHelpSidebarView.Main);

        setHelpSidebarOpened(false);
        setTawkChatOpened(false);
    };

    return (
        <Drawer
            open={isHelpSidebarOpened}
            onClose={handleClose}
            anchor="right"
        >
            <FlexColumn width={400} height="100vh" justifyContent="space-between">
                {view === EHelpSidebarView.Main && <HelpSidebarMainContent setView={setView} />}
                {view === EHelpSidebarView.KeyboardShortcuts && <HelpSidebarKeyboardShortcuts setView={setView} />}
            </FlexColumn>
        </Drawer>
    );
}
