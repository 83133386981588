
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import type { JwtPayload } from 'jwt-decode';

import { usePollConversationsServiceWorker } from 'src/services/service-workers';
import Auth from 'src/services/api/auth/Auth';
import LandlordLayout from 'src/components/layout/landlord/LandlordLayout';
import AuthenticatedStateProvider, { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import PaywallPage from 'src/pages/paywall/Paywall';

import 'src/components/layout/landlord/LandlordLayout.css';

const REFRESH_TOKEN_INTERVAL_MS = 30 * 60 * 1000; // 30 minutes

interface IJwtPayload extends JwtPayload {
    ibp?: number;
}

function AuthenticatedContainer() {
    const { loggedInUser } = useAuthenticatedState();

    useEffect(() => {
        if (loggedInUser && !loggedInUser.is_onboarding_complete) {
            window.location.href = '/landlord/onboard';
        }
    }, [loggedInUser]);

    // Refresh the access token every 30 minutes
    useEffect(() => {
        const intervalId = window.setInterval(
            Auth.refreshAccessToken,
            REFRESH_TOKEN_INTERVAL_MS
        );

        return () => window.clearInterval(intervalId);
    }, []);

    // Refresh the access token when the user switches back to the tab
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                const jwt = jwtDecode(Auth.accessToken!);
                const now = dayjs();
                const issuedAt = dayjs.unix(jwt.iat || 0);

                // If the token is older than 30 minutes, refresh it
                if (now.diff(issuedAt, 'minute') > 30) {
                    Auth.refreshAccessToken();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const isOnboardingComplete = loggedInUser.is_onboarding_complete;
    const isTeamActive = loggedInUser.team?.is_active;

    // Check if access token has paywall bypass field
    const accessToken = jwtDecode<IJwtPayload>(Auth.accessToken!);
    const isBypassPaywall = accessToken.ibp;

    if (isOnboardingComplete && !isTeamActive && !isBypassPaywall) {
        return (
            <PaywallPage user={loggedInUser} />
        );
    }

    usePollConversationsServiceWorker();

    return (
        <LandlordLayout>
            <Outlet />
        </LandlordLayout>
    );
}

export default function AuthenticatedContainerWithProvider() {
    return (
        <AuthenticatedStateProvider>
            <AuthenticatedContainer />
        </AuthenticatedStateProvider>
    );
}