import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead } from 'src/services/api/leads/types';
import type { IUpdateLeadResponse } from 'src/services/api/leads/use-update-lead';
import { useNotifications } from 'src/notifications';
import { getApiErrorMessage } from 'src/services/api/utils';
import { apiClient } from 'src/services/api/client';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { queryKeys } from 'src/services/api/constants';

type IUpdateLeadsRequest = Array<Pick<ILead, 'uuid'> & Partial<ILead>>;

export const useUpdateLeads = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        ILead[],
        IStandardErrorResponse,
        IUpdateLeadsRequest
    >(async (payload) => {
        const res = await Promise.allSettled(payload.map((lead) => {
            const path = `${GET_LEADS_PATH}/${lead.uuid}`;
            return apiClient(path, {
                method: 'patch',
                json: lead,
            }).json<IUpdateLeadResponse>();
        }));

        const oldLeads = queryClient.getQueryData<ILead[]>(queryKeys.leads) || [];

        const updatedLeads = oldLeads.map((lead) => {
            const updatedLead = res
                .find((o) => o.status === 'fulfilled' && o.value.lead.uuid === lead.uuid);

            if (!updatedLead || updatedLead.status === 'rejected') {
                return lead;
            }

            return updatedLead.value.lead;
        });

        queryClient.setQueryData<ILead[]>(queryKeys.leads, updatedLeads);
        for (const lead of updatedLeads) {
            queryClient.setQueryData<ILead>([queryKeys.leads, lead.uuid], lead);
        }

        const errors = res.filter((r) => r.status === 'rejected');
        if (errors.length > 0) {
            throw errors.map(o => o.reason);
        } else {
            addNotification('Leads updated', 'success');
        }

        return updatedLeads;
    }, {
        onError: (errors) => {
            const errorMessage = getApiErrorMessage(errors) || 'Failed to update leads';
            addNotification(errorMessage, 'error');
        }
    });
};
