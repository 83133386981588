import React, { useRef, useState } from 'react';
import pluralize from 'pluralize';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { EMessageChannel, useSendMessages } from 'src/services/api';
import Dialog from 'src/components/dialog/Dialog';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

type Props = {
    open: boolean,
    onClose: () => void,
};

export default function BulkMessageLeadsModal({
    open,
    onClose,
}: Props) {
    const { mutateAsync: sendMessages } = useSendMessages();

    const {
        leads,
        selectedConversations,
        setSelectedConversations,
    } = useConversationsPageContext();

    const numEmails = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.EMAIL;
    }).length;
    const numSms = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.SMS;
    }).length;
    const numFacebookMessages = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.FACEBOOK_MESSENGER;
    }).length;

    const formRef = useRef<HTMLFormElement>(null);

    const [message, setMessage] = useState('');
    const [isSending, setSending] = useState(false);

    const handleSendMessage = async (event: React.FormEvent) => {
        event.preventDefault();
        setSending(true);

        try {
            const validMessages = selectedConversations.filter((conversation) => {
                const lead = leads.find((o) => o.uuid === conversation.lead_uuid);
                if (lead?.email?.endsWith('@example.com')) {
                    console.debug('Not sending an email to example.com');
                    return false;
                }

                return true;
            });

            await sendMessages(validMessages.map((conversation) => {
                return {
                    leadUuid: conversation.lead_uuid,
                    channel: conversation.latest_message_channel,
                    message,
                };
            }));

            setSelectedConversations([]);
            onClose();
        } finally {
            setMessage('');
            setSending(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Send a bulk message</DialogTitle>

            <DialogContent>
                <form ref={formRef} onSubmit={handleSendMessage}>
                    <TextField
                        placeholder="Your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        minRows={8}
                        required
                        fullWidth
                    />
                </form>

                <DialogContentText mt={2}>
                    {getActionSummaryText(numEmails, numSms, numFacebookMessages)}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                    disabled={isSending}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={() => formRef.current?.requestSubmit()}
                    loading={isSending}
                >
                    Send
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const getActionSummaryText = (
    numEmails: number,
    numSms: number,
    numFacebookMessages: number,
) => {
    if (!numEmails && !numSms && !numFacebookMessages) {
        return null;
    }

    return `This action will send ${numEmails} ${pluralize('email', numEmails)}, ${numSms} SMS ${pluralize('message', numSms)}, and ${numFacebookMessages} Facebook Messenger ${pluralize('message', numFacebookMessages)}.`;
};