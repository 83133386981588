import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ICreateLeadRequest } from 'src/services/api/leads/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';

const CREATE_LEADS_PATH = `${GET_LEADS_PATH}/upload-bulk`;

type ICreateLeadErrors = {
        index: {
            dup_reason: string;
            error_code: string;
            msg: string;
        }
}

type ICreateLeadsResponse = {
    num_added: number;
    num_failed: number;
    failed_lead_names: string[];
    errors: ICreateLeadErrors;
}

type ICreateLeadsRequest = {
    leads: ICreateLeadRequest[];
}

/**
 * Doesn't show notification.
 * The caller is responsive for showing the appropriate notification to the user.
 */
export const useCreateLeads = () => {
    const queryClient = useQueryClient();

    return useMutation<
        ICreateLeadsResponse,
        IStandardErrorResponse[],
        ICreateLeadsRequest
    >(
        (payload) => {
            return apiClient(CREATE_LEADS_PATH, {
                method: 'post',
                json: payload,
                timeout: 60000
            }).json<ICreateLeadsResponse>();
        }, {
            onSuccess: () => {
                // We have to invalidate the leads query to refetch the data
                // because we don't get the created leads in the response
                queryClient.invalidateQueries(queryKeys.leads);
            }
        }
    );
};
