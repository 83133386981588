import { type IMessage, EMessageChannel } from 'src/services/api';
import type { ILead } from 'src/services/api';

export class LeadUtils {
    static getDefaultMessageChannel(lead: ILead, messages: IMessage[]): EMessageChannel {
        const lastMessage = messages.at(-1);
        const lastMessageChannel = lastMessage?.channel;

        // Priority: Facebook Messenger > SMS > Email
        let defaultLeadChannel = EMessageChannel.EMAIL;
        if (lead.phone_number) {
            defaultLeadChannel = EMessageChannel.SMS;
        }
        if (lead.facebook_user_id) {
            defaultLeadChannel = EMessageChannel.FACEBOOK_MESSENGER;
        }

        return lastMessageChannel || defaultLeadChannel;
    }

    static getLeadName(lead?: ILead): string {
        if (!lead || !lead.name) {
            return 'Unknown name';
        }

        if (lead.name === 'No Name' && lead.ingestion_source === 'showmojo') {
            return 'Unknown name (ShowMojo)';
        }

        return lead.name;
    }
}
