
import React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';

import { Auth } from 'src/services/api';
import { ThemeProvider } from 'src/theme';
import { LANDLORD_HOME_PATH } from 'src/routes';
import Responsive from 'src/components/responsive/Responsive';
import { Flex, FlexColumn } from 'src/components/flex';
import Link from 'src/components/link/Link';
import { useAppState } from 'src/AppStateProvider';
import NavigationLink from 'src/components/layout/landlord/NavigationLink';
import { LogOutIcon, PanelLeftIcon } from 'src/icons';
import { links } from 'src/components/layout/landlord/links';
import { useLandlordLayoutContext } from 'src/components/layout/landlord/LandlordLayoutProvider';

import styles from 'src/components/layout/landlord/LandlordLayout.module.css';

type Props = {
    onNavigationChange: () => void;
}

export default function DesktopNavigationSidebar({
    onNavigationChange
}: Props) {
    const { setHelpSidebarOpened } = useAppState();
    const { isSidebarCollapsed, setSidebarCollapsed, sidebarWidth } = useLandlordLayoutContext();

    const handleLogout = () => {
        Auth.logout();
    };

    const handleClickSidebar = () => {
        setSidebarCollapsed(!isSidebarCollapsed);
    };

    return (
        <>
            <Responsive desktop>
                <Box
                    className={clsx(
                        styles.sidebar,
                        styles.sidebarPadding,
                    )}
                    sx={{
                        width: sidebarWidth,
                        minWidth: sidebarWidth,
                    }}
                />
            </Responsive>

            <ThemeProvider>
                <Responsive desktop>
                    <Paper
                        square
                        className={styles.sidebar}
                        sx={{ width: sidebarWidth, minWidth: sidebarWidth }}
                    >
                        <FlexColumn height="100vh" justifyContent="space-between">
                            <Flex flexDirection={isSidebarCollapsed ? 'column' : 'row'}>
                                <Link href={LANDLORD_HOME_PATH} data-testid="home-link">
                                    <Flex height={60} p={1} alignItems="center">
                                        <img
                                            src="/img/reffie-logos/SantaHat_Logo.png"
                                            alt="reffie-logo"
                                            height="40px"
                                        />
                                        {!isSidebarCollapsed && (
                                            <Typography color="primary" fontWeight="bold">
                                                Reffie
                                            </Typography>
                                        )}
                                    </Flex>
                                </Link>

                                <IconButton
                                    onClick={handleClickSidebar}
                                    data-testid="collapse-toggle"
                                >
                                    <PanelLeftIcon />
                                </IconButton>
                            </Flex>

                            <FlexColumn className="prettyScrollbar" flex={1} sx={{ overflowY: 'auto' }}>
                                <List>
                                    {links.map((link) => (
                                        <NavigationLink
                                            key={link.text}
                                            text={isSidebarCollapsed ? '' : link.text}
                                            icon={link.icon}
                                            link={link.link}
                                            onClick={onNavigationChange}
                                        />
                                    ))}
                                </List>
                            </FlexColumn>

                            <List>
                                <NavigationLink
                                    text={isSidebarCollapsed ? '' : 'Help'}
                                    icon={<HelpIcon />}
                                    onClick={() => setHelpSidebarOpened(prev => !prev)}
                                />

                                <NavigationLink
                                    text={isSidebarCollapsed ? '' : 'Logout'}
                                    icon={<LogOutIcon />}
                                    onClick={handleLogout}
                                />
                            </List>
                        </FlexColumn>
                    </Paper>
                </Responsive>
            </ThemeProvider>
        </>
    );
}
