import React, { useState } from 'react';

import { ThemeProvider } from 'src/theme';
import { FlexColumn } from 'src/components/flex';
import PageTitle from 'src/components/page-title/PageTitle';
import Provider from 'src/pages/landlord/leads/messages/context/Provider';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';
import MessagesContainer from 'src/pages/landlord/leads/messages/components/messages-container';
import Header from 'src/pages/landlord/leads/messages/components/header';
import Input from 'src/pages/landlord/leads/messages/components/input';
import Hotkeys from 'src/pages/landlord/leads/messages/components/hotkeys/LeadMessagesHotkeys';
import LeadSidebar from 'src/components/lead-sidebar/LeadSidebar';
import SmartFieldsModal from 'src/pages/landlord/leads/messages/components/smart-fields-modal';

import styles from 'src/pages/landlord/leads/messages/LeadMessagesPage.module.css';

function LeadMessagesPageContent() {
    const {
        showings,
        lead,
        property,
        messages,
        sendMessage
    } = useLeadMessagesPageContext();

    const [isShowLeadDetailsModal, setIsShowLeadDetailsModal] = useState(false);

    return (
        <div className={styles.root}>
            <FlexColumn flex={1} rowGap={0}>
                <Header handleShowLeadDetails={setIsShowLeadDetailsModal} />
                <SmartFieldsModal
                    open={isShowLeadDetailsModal}
                    onClose={() => setIsShowLeadDetailsModal(false)}
                    lead={lead}
                    property={property}
                    showings={showings}
                />
                <MessagesContainer />
                <FlexColumn rowGap={0}>
                    <Input />
                    <Hotkeys />
                </FlexColumn>
            </FlexColumn>

            <LeadSidebar
                lead={lead}
                property={property}
                showings={showings}
                messages={messages}
                sendMessage={sendMessage}
            />
        </div>
    );
}

export default function LeadMessagesPage() {
    return (
        <ThemeProvider>
            <Provider>
                <PageTitle title="Reffie | Messages">
                    <LeadMessagesPageContent />
                </PageTitle>
            </Provider>
        </ThemeProvider>
    );
}
