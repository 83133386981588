import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';

import { useAppState } from 'src/AppStateProvider';
import { Flex, FlexColumn } from 'src/components/flex';
import ButtonLink from 'src/components/button-link';
import { EHelpSidebarView } from 'src/components/layout/landlord/help-sidebar/types';

function PopularTopic({
    href,
    title,
    description
}: {
    href: string;
    title: string;
    description: string;
}) {
    return (
        <Box
            component={Link}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            p={2}
            display="flex"
            columnGap={2}
            border="1px solid #EEE"
            borderRadius={2}
        >
            <ArticleIcon color="primary" sx={{ mt: 0.5 }} />

            <FlexColumn rowGap={0.5}>
                <Typography fontWeight={500} color="primary.main">{title}</Typography>
                <Typography variant="body2">{description}</Typography>
            </FlexColumn>
        </Box>
    );
}

type Props = {
    setView: (view: EHelpSidebarView) => void;
}

export default function HelpSidebarMainContent({ setView }: Props) {
    const { setTawkChatOpened } = useAppState();

    return (
        <>
            <FlexColumn p={2} rowGap={2}>
                <FlexColumn rowGap={0}>
                    <Typography variant="h6" fontWeight="bold">Help</Typography>
                    <Typography variant="body2">How can we help you?</Typography>
                </FlexColumn>

                <FlexColumn>
                    <Flex justifyContent="space-between">
                        <Typography fontWeight="bold">
                            Popular topics
                        </Typography>

                        <ButtonLink
                            href="https://reffie.tawk.help/"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="text"
                            size="small"
                        >
                            See all
                        </ButtonLink>
                    </Flex>

                    <PopularTopic
                        href="https://reffie.tawk.help/article/how-to-navigate-reffie"
                        title="How to navigate Reffie"
                        description="Explore the Reffie platform via the navigation bar."
                    />

                    <PopularTopic
                        href="https://reffie.tawk.help/article/sync-your-leads"
                        title="Sync your leads"
                        description="Sync your rental leads to Reffie in real-time, and also start conversations from Reffie."
                    />

                    <PopularTopic
                        href="https://reffie.tawk.help/article/automate-your-workflows-with-playbooks"
                        title="Automate your workflows with Playbooks"
                        description="Customize Reffie to your team's process and operations! Below are some examples users love <3."
                    />
                </FlexColumn>
            </FlexColumn>

            <FlexColumn>
                <Flex
                    p={2}
                    pb={1}
                    component="a"
                    onClick={() => setView(EHelpSidebarView.KeyboardShortcuts)}
                    alignItems="center"
                    columnGap={0.5}
                    sx={{ cursor: 'pointer' }}
                >
                    <KeyboardCommandKeyIcon color="action" sx={{ fontSize: 20 }} />
                    <Typography variant="body2">Keyboard shortcuts</Typography>
                </Flex>

                <Box bgcolor="primary.main">
                    <FlexColumn p={2} rowGap={2} color="common.white">
                        <FlexColumn rowGap={0.5}>
                            <Typography fontWeight="bold" color="inherit">
                                Contact Support
                            </Typography>
                            <Typography variant="body2" color="inherit">
                                Fill a form and our customer support team will get back to you as soon as possible.
                            </Typography>
                        </FlexColumn>
                        <Button
                            onClick={() => setTawkChatOpened(true)}
                            variant="contained"
                            color="primary"
                            sx={{
                                bgcolor: 'common.white',
                                color: 'primary.main',

                                '&:hover': {
                                    bgcolor: 'common.white',
                                }
                            }}
                        >
                            Submit a ticket
                        </Button>
                    </FlexColumn>
                </Box>
            </FlexColumn>
        </>
    );
}
